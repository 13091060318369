import React, {useEffect, useState, useCallback} from "react";
import apiService from "../misc/api";


function formatDate(date) {
    console.log(date)
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    }

export default function SalesReport () {
  const [sales, setSales] = useState([])
  const [loadedTotalSum, setLoadedTotalSum] = useState(0)
  const [loadedTotalPositions, setLoadedTotalPositions] = useState(0)
  const [soldTotalSum, setSoldTotalSum] = useState(0)
  const [soldTotalPositions, setSoldTotalPositions] = useState(0)
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const [dateTo, setDateTo] = useState(currentDate)
  const [dateFrom, setDateFrom] = useState(currentDate)



  const fetchSales = useCallback(async () => {
    const requestData = {
      start: formatDate(dateFrom),
      end: formatDate(dateTo)
    }
    try {
      const data = await apiService.apiRequestGet("sales_report", requestData)
      setSales(data.report)
      setLoadedTotalSum(data.loaded_sum)
      setLoadedTotalPositions(data.loaded_positions)
      setSoldTotalSum(data.sold_sum)
      setSoldTotalPositions(data.sold_positions)
    } catch {
      console.log('Error');
    }
  }, [dateFrom, dateTo])

  useEffect(() => {fetchSales()}, [fetchSales])

    return(
      <div>
        <div>
          <label htmlFor="dateFrom">С: </label>
          <input
            type="date"
            value={formatDate(dateFrom)}
            onChange={(e) => setDateFrom(new Date(e.target.value))}/>
          <label htmlFor="dateFrom">С: </label>
          <input
            type="date"
            value={formatDate(dateTo)}
            onChange={(e) => setDateTo(new Date(e.target.value))}/>
        </div>
        <br/>
        <table border="1" align="center">
          <thead>
          <tr>
            <th>Артикул</th>
            <th>Отгружено (руб)</th>
            <th>Отгружено (шт)</th>
            <th>Реализовано (руб)</th>
            <th>Реализовано (шт)</th>
          </tr>
          </thead>
          <tbody>
          {
            sales.map((sale) =>
              <tr>
                <td>{sale.article}</td>
                <td>{sale.loaded_sum} ₽</td>
                <td>{sale.loaded_positions} шт</td>
                <td>{sale.sold_sum} ₽</td>
                <td>{sale.sold_positions} шт</td>
              </tr>
            )
          }
          </tbody>
          <tfoot>
          <tr>
            <th><b>ИТОГО:</b></th>
            <th>{loadedTotalSum} ₽</th>
            <th>{loadedTotalPositions} шт</th>
            <th>{soldTotalSum} ₽</th>
            <th>{soldTotalPositions} шт</th>
          </tr>
          </tfoot>
        </table>
      </div>
    )
}

