import React, { useState } from 'react';
import apiService from '../misc/api';

function Login() {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [isError, setIsError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const authData = {
      username: login,
      password: password
    }

    try {
      const response = await apiService.apiRequestPost("login", authData)
      if (response.status === 403) {
        setIsError(true);
      } else {
        localStorage.setItem("pradoAppToken", response.prado_app_token);
        window.location.href = '/products';
      }
    } catch (error) {
        console.error('Ошибка запроса:', error);
        throw error;
    }
  } 

  const ErrorMessage = ({isError}) => {
    if (isError) {
      return(
        <div align="center" style={{ color: 'red' }}>
          Неверное имя пользователя или пароль
        </div>
      )
    } else {
      return null;
    }
  }

  return (
    <div align="center">
      <h2>Авторизация</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="login">Логин: </label>
          <input
            type="text"
            id="login"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            onFocus={() => setIsError(false)}
          />
        </div>
        <br/>
        <div>
          <label htmlFor="password">Пароль: </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onFocus={() => setIsError(false)}
          />
        </div>
        <br/>
        <button type="submit">Войти</button>
      </form>
      <ErrorMessage isError={isError} />
    </div>
  );
}


export default Login;