import React, { useEffect, useState } from "react";
import apiService from "../../misc/api";
import './News.css';


function News () {

    const [news, setNews] = useState([])
    const fetchNews = async () => {
        try {
            const news = await apiService.apiRequestGet("news")
            setNews(news)
        } catch {
            return;
        }
    }

    useEffect(() => {
        fetchNews()
    }, [])
    
    return(
        <ul>
            {news.map((news) => 
            <li key={news.id}>
                <div className='date-news'>
                    {news.date}
                </div>
                <div className='article-news'>
                    {news.article}
                </div>
                {/*// <p>*/}
                {/*//     <b>{news.date}</b> ||| <i>{news.article}</i>*/}
                {/*// </p>*/}
            </li>)}
        </ul>
    )
}

export default News;