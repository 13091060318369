import React from "react";
import { NavLink } from "react-router-dom";
import './Header.css'
import logo from './img.png'

function Header () {
    return (
      <header>
        <NavLink to="/" className="logo-link">
          <img src={logo} alt="Logo"/>
        </NavLink>

        <nav>
        {/*<NavLink to="/">Главная</NavLink>*/}
          <NavLink to="/sales_report">Отчёт по реализации</NavLink>
          <NavLink to="/active_orders">Активные заказы</NavLink>
          <NavLink to="/fbo_stock">FBO остатки</NavLink>
          <NavLink to="/products">Остатки и цены</NavLink>
        </nav>
        <hr/>
      </header>
    )
}

export default Header;