import React, { useEffect, useState } from "react";
import apiService from "../misc/api";

function ActiveOrders() {
    const [orders, setOrders] = useState([])
    const fetchOrders = async () => {
        try {
            const data = await apiService.apiRequestGet("active_orders")
            setOrders(data)
        } catch {
            console.log('Error');
        }
    }

    useEffect(() => {
        fetchOrders()
    }, [])

    return(
        <table border="1" align="center">
            <thead>
            <tr>
                <th>№</th>
                <th>Номер</th>
                <th>Дата</th>
                <th>Артикул</th>
                <th>Тип доставки</th>
                <th>Склад</th>
                <th>Направление</th>
                <th>Цена закупки</th>
                <th>Цена продажи</th>
                <th>Статус продавца</th>
                <th>Статус покупателя</th>
            </tr>
            </thead>
            <tbody>
            {
                orders.map((orders) =>
                    <tr>
                        <td>{orders.id}</td>
                        <td>{orders.number}</td>
                        <td>{orders.date}</td>
                        <td>{orders.article}</td>
                        <td>{orders.delivery_type}</td>
                        <td>{orders.warehouse}</td>
                        <td>{orders.destination}</td>
                <td>{orders.seller_price} ₽</td>
                <td>{orders.client_price} ₽</td>
                <td>{orders.seller_status}</td>
                <td>{orders.client_status}</td>
            </tr>
            )
              }
            </tbody>


    </table>
    )
}

export default ActiveOrders;