import React, { useEffect, useState } from "react";
import apiService from "../misc/api";

function FboStock() {
    const [stocks, setStock] = useState([])
    const fetchStocks = async () => {
        try {
            const data = await apiService.apiRequestGet("fbo_stock")
            setStock(data)
        } catch {
            return;
        }
    }

    useEffect(() => {
        fetchStocks()
    }, [])

    return(
        <table border="1" align="center">
        <tr>
            <th>Артикул</th>
            <th>Склад</th>
            <th>Остаток</th>
            <th>Цена продажи</th>
        </tr>
        {
            stocks.map((stocks) =>
            <tr>
                <td>{stocks.article}</td>
                <td>{stocks.warehouse}</td>
                <td>{stocks.quantity}</td>
                <td>{stocks.sale_price}</td>
            </tr>
            )
        }
    </table>
    )
}

export default FboStock;
