import { Link, generatePath, Outlet } from "react-router-dom";
import React, { useEffect, useState } from "react";
import ReactModal from 'react-modal';
import apiService from "../../misc/api";

function Products() {
  const [warehouses, setWarehouses] = useState([])
  const [nonDefaultPrices, setNonDefaultPrices] = useState([])
  const fetchWarehouses = async () => {
    try {
      const data = await apiService.apiRequestGet("products")
      setWarehouses(data.warehouses)
      setNonDefaultPrices(data.non_default_prices)
    } catch {
      return;
    } 
  }

  useEffect(() => {
    fetchWarehouses()
  }, [])

  useEffect(() => {
    setUpdatedRows(nonDefaultPrices);
    addAccept()
  }, [nonDefaultPrices])

  const addAccept = () => {
    setUpdatedRows((prevRows) => 
      prevRows.map((row) => ({ ...row, accept: false})))
  }

  const [updatedRows, setUpdatedRows] = useState(nonDefaultPrices)

  const handleInputChange = (event, productId, field) => {
    const updatedValue = event.target.checked;
    setUpdatedRows((prevRows) => 
      prevRows.map((row) => 
        row.wb_id === productId ? { ...row, [field]: updatedValue} : row
        )
    )
  }

  const pushNonDefaultData = async () => {
    await apiService.apiRequestPost(
      "update_prices",
      updatedRows
    )
    openPopup();
  }

  const [isOpen, setIsOpen] = useState(false);

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
    addAccept();
  };

  const Popup = ({ isOpen }) => {
    
    return (
      <ReactModal
        isOpen={isOpen}
        onRequestClose={closePopup}
        contentLabel="Pop-up"
        ariaHideApp={false}
      >
        <h1>Изменения сохранены</h1>
        <p>
            Изменения были сохранены. Если вы меняли цену продажи, то подтвердите передайте изменения в Wildberries (таблица в верхней части страницы). 
            Изменения остатков обновятся в течении часа
        </p>
        <button onClick={closePopup}>Понятно</button>
      </ReactModal>
    );
  };

  const NonDefaultTable = () => {

    if (updatedRows.length === 0) {
      return null;
    }

    return (
      <div align="center">
        <h4>Цены с отклонениями</h4>
        <table border="1">
          <thead>
            <tr>
              <th>Артикул</th>
              <th>WB ID</th>
              <th>Установленная цена</th>
              <th>Цена на WB</th>
              <th>Закупочная цена</th>
              <th>Выручка</th>
              <th>---</th>
            </tr>
          </thead>
          <tbody>
            {updatedRows.map((row) => (
              <tr key={row.wb_id} style={{ color: row.purchase_price > row.revenue ? 'red' : 'white' }}>
                <td>{row.article}</td>
                <td>{row.wb_id}</td>
                <td>{row.sale_price} ₽</td>
                <td>{row.real_price} ₽</td>
                <td>{row.purchase_price} ₽</td>
                <td>{row.revenue} ₽</td>
                <td>
                  <input
                    type="checkbox" 
                    name="accept"
                    checked={row.accept}
                    onChange={(e) => 
                      handleInputChange(e, row.wb_id, "accept")
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button type="submit" onClick={() => pushNonDefaultData()}>
          Сохранить
        </button>
        <Popup isOpen={isOpen} />
      </div>
    )
  }

  const pathTemplate = "/products/:warehouseId/";

  return (
    <div>
      {warehouses.map((warehouse) =>
        <Link 
          to={generatePath(pathTemplate, { warehouseId: warehouse.id })} 
          key={warehouse.name}>
            {warehouse.name} |
        </Link>)}
      <NonDefaultTable />
      <hr />
      <Outlet />
    </div>
  )
}

export default Products;