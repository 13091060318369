import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Header from './components/Header/Header';
import News from './components/News/News';
import ActiveOrders from './components/ActiveOrders';
import FboStock from './components/FboStock';
import SalesReport from './components/SalesReport';
import Products from './components/products/Products';
import Warehouse from './components/products/Warehouse';
import Login from './components/Login';
import './Caviar Dreams/CaviarDreams.ttf'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div className='app'>
    <Router >
      <Header />
      <Routes>
        <Route path="/" element={<News />} />
        <Route path='/login' element={<Login />} />
        <Route path="/active_orders" element={<ActiveOrders />} />
        <Route path="/fbo_stock" element={<FboStock />} />
        <Route path="/sales_report" element={<SalesReport />} />
        <Route path='/products' element={<Products />}>
          <Route path=':warehouseId' element={<Warehouse />} />
        </Route>
      </Routes>
    </Router>
  </div>
);
reportWebVitals();
