import React, {useEffect, useState, useCallback} from "react";
import { useParams } from "react-router-dom";
import ReactModal from 'react-modal';
import apiService from "../../misc/api";


function Warehouse () {
    let { warehouseId } = useParams();
  const [warehouseName, setWarehouseName] = useState([]);
  const [products, setProducts] = useState([]);

  const fetchWarehouse = useCallback(async () => {
    try {
      const requestData = {warehouse_id: warehouseId}
      const data = await apiService.apiRequestGet(
        `warehouse`,
        requestData
      )
      setWarehouseName(data.warehouse_name);
      setProducts(data.current_products);
    } catch {
      return;
    }
  }, [warehouseId]);

  useEffect(() => {
    fetchWarehouse();
  }, [fetchWarehouse]);

  useEffect(() => {
    setUpdatedProducts(products);
    setParamsToZero();
  }, [products]);

  const setParamsToZero = () => {
    setUpdatedProducts((prevProducts) =>
      prevProducts.map((product) => ({ ...product, quantity: "", sale_price: "", purchase_price: "", warehouse_id: warehouseId })),
    );
  };


  const [updatedProducts, setUpdatedProducts] = useState(products);

  const handleInputChange = (event, productId, field) => {
    const updatedValue = event.target.value;
    setUpdatedProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === productId ? { ...product, [field]: updatedValue } : product
      )
    );
  };

  const pushProductsData = async () => {
    await apiService.apiRequestPost(
      "update_products",
      updatedProducts
    )
    openPopup();
  }

  const [isOpen, setIsOpen] = useState(false);

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
    setParamsToZero();
    window.location.reload()
  };

  const Popup = ({ isOpen }) => {
    
    return (
      <ReactModal
        isOpen={isOpen}
        onRequestClose={closePopup}
        contentLabel="Pop-up"
        ariaHideApp={false}
      >
        <h1>Изменения сохранены</h1>
        <p>
            Изменения были сохранены. Если вы меняли цену продажи, то подтвердите передайте изменения в Wildberries (таблица в верхней части страницы). 
            Изменения остатков обновятся в течении часа
        </p>
        <button onClick={closePopup}>Понятно</button>
      </ReactModal>
    );
  };

    return (
        <div>
          <h2 align="center">{warehouseName}</h2>
          <table border="0" align="center">
            <thead>
              <tr>
                <th>№</th>
                <th>Артикул</th>
                <th>Наименование</th>
                <th>WB ID</th>
                <th>Цена закупки</th>
                <th>Цена продажи</th>
                <th>Остаток FBS</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <tr key={product.id}>
                  <td>{product.id}</td>
                  <td>{product.article}</td>
                  <td>{product.title}</td>
                  <td>{product.wb_id}</td>
                  <td>
                    <input
                      type="number"
                      name="purchase_price"
                      placeholder={product.purchase_price}
                      onChange={(e) =>
                        handleInputChange(e, product.id, "purchase_price")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="sale_price"
                      placeholder={product.sale_price}
                      onChange={(e) =>
                        handleInputChange(e, product.id, "sale_price")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="quantity"
                      placeholder={product.quantity}
                      onChange={(e) =>
                        handleInputChange(e, product.id, "quantity")
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button type="submit" onClick={() => pushProductsData()}>Сохранить</button>
          <Popup isOpen={isOpen} />
        </div>
      );
    
}

export default Warehouse