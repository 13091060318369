import axios from 'axios';

function authHeader() {
  const token = localStorage.getItem("pradoAppToken");
  return { Authorization: "Bearer " + token }
}

// const host = window.location.origin
const host = "https://twopercentcrm.ru"

const apiRequestGet = async (router, params) => {
    try {
      const response = await axios.get(
        `${host}/api/${router}`,
        {
          params: params,
          headers: {
            ...authHeader(),
          },
        }
      );
      console.log(response)
      return response.data
      
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error('Ошибка запроса: Неавторизован');
        window.location.href = "/login"
      } else {
        console.error('Ошибка запроса:', error);
      }
    }
  };

const apiRequestPost = async (router, data) => {
    try {
      const response = await axios.post(
        `${host}/api/${router}`,
        data,
        {
          headers: {
            ...authHeader(),
          },
        }
      );

        if (response.status === 403) {
          return response
        }
        
        if (response.status === 401) {
          window.location.href = '/login';
        } else {
          return response.data;
        }
    } catch (error) {
        console.error('Ошибка запроса:', error);
        throw error;
    }
};

const apiService = {
  apiRequestGet,
  apiRequestPost
};

export default apiService;
